.sqaure-card-body{
    background-color: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    height: 150px;
    width: 220px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    cursor: pointer;
}
.sqaure-card-body-contentDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  cursor: pointer;
  height: inherit;
  width: inherit;
}

.square-card-space-name{
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #2E375B;

}

.square-card-space-capacity{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: rgba(68, 68, 68, 0.6);
    display: flex;
    align-items: center;
    flex-direction: row;
}

.square-card-status-for-space{
    /* background: #F65162; */
    background: gray;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    padding: 7px 20px;
}
.square-card-status-for-space-reset-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width : 776px) {    
  .sqaure-card-body {
    text-align: center;
    height: 130px;
    width: 190px;
    font-size: large;
  }
  .square-card-status-for-space{
      font-size: 10px;
      padding: 2px 8px;
  }
}

@media (max-width : 498px) {    
    .sqaure-card-body {
      text-align: center;
      height: 130px;
      width: 150px;
      font-size: large;
    }
    .square-card-status-for-space{
        font-size: 10px;
        padding: 2px 8px;
    }
  }

  @media (max-width : 358px) {    
    .sqaure-card-body {
      text-align: center;
      height: 110px;
      width: 110px;
      font-size: large;
    }
}