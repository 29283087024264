@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy'), url(../../assets/fonts/Gilroy-ExtraBold.otf) format('opentype');
}
/* @font-face {
	font-family: 'Gilroy';
	src: local('Gilroy'), url(../../assets/fonts/Gilroy-Light.otf) format('opentype');
} */


.header-secondary{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 6.5rem;
    width: 100%;
	font-family: 'Gilroy';
}

.header-secondary-content1{
    display: flex;
    flex-direction: row;
}

.header-secondary-content1-item1{
    border: none;
    background: transparent;
    align-self: center;
}

.header-secondary-content1-item2{
    display: flex;
    flex-direction: column;
    margin: 1rem;
}
.header-secondary-content1-item2-floor-number{
    color: #444444;
    font-weight: 700;
    opacity: 0.5;
	font-family: 'Gilroy';
    font-size: 0.9rem;

}
.header-secondary-content1-item2-floor-name{
    font-weight: 700;
    font-size: 1.28rem;
    color: #161056;
	font-style: normal;
	line-height: 24px;
	font-family: 'Gilroy';

}
.header-secondary-content2{
    border: 1px solid #38D68B;
    border-radius: 5px;
    font-weight: 700;
    font-size: 0.6rem;
    color: #2EC77E;
    padding: 0.1rem 1rem;
}