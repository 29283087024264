.loader {
    height: 50px;
    width: 50px;
    background: transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}