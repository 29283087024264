.hello {
    position: absolute;
}

.circle {
    position: absolute;
}

.fp-comp {
    height: auto;
    width: auto;
    /* overflow: scroll; */
}
