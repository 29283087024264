.screenShotEffect{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    animation: screenShot 0.3s normal forwards;
    opacity: 0;
}

@keyframes screenShot {
	0% {opacity: 0;  z-index: 10;}
    50% {opacity: 1;  z-index: 10;}
	100% {opacity: 0;  z-index: -1}
}
