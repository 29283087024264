.rectangle-card-body {
    width: 100%;
    min-height: 7.2rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.rectangle-card-body-top {
    display: flex;
    justify-content: space-between;
}

.rectangle-card-body-top-content1 {
    /* font-family: 'Nunito'; */
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    color: #2E375B;
}

.rectangle-card-body-top-content2 {
    /* font-family: 'Nunito'; */
    /* font-size: 12px;
    padding: 0.1rem 1rem;
    color: #FF9777;
    background: transparent;
    border: 1px solid #FF9777;
    border-radius: 5px; */
}

.rectangle-card-body-bottom {
    /* font-family: 'Nunito'; */
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    color: rgba(68, 68, 68, 0.6);
    display: flex;
    flex-direction: column;
}

.rectangle-card-status-for-space-reset-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}