.grid {
	margin: 0px 20%;
	/* outline: 1px solid lightgrey; */
}

.rolar {
	border-radius: 15px;
	box-shadow: 0px 0px 4px 0px red;
}
.set-up-grid-ht-orientation{
	font-family: Nunito;
	font-style: normal;
	font-weight: 700; 
	line-height: 15px; 
	color: #38D68B;
	font-size:0.75rem;
}
textarea:focus, input:focus{
    outline: none;
}
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {  
   -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

@media only screen and (max-width: 600px) {

	.grid {
		margin: 0px 0%;
		outline: 1px solid lightgrey;
		flex-grow: 1;
		flex-basis: 0;
		width: fit-content;
	}

	.gridHeader,
	.gridHeaderHO {
		/* padding: 0% 8%; */
	}
}