.icon-logout {
    display: flex;
    justify-content: space-between;

}

.logout-background {
    background: #FFFFFF;
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    width: 2rem;
    display: grid;
    justify-content: center;
    align-content: center;
    border: none;
}

.top-head {
    background-color: #e5e5e5;
    display: flex;
    justify-content: center;
    align-content: space-evenly;
    flex-direction: column;
    width: 90%;
    min-height: calc(100vh - 70%);
    flex-grow: 3;
}

.top-body {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */

}

.select-floor-squareCardDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 0.8rem;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .justify-content-between {
        justify-content: space-around !important;
    }
}