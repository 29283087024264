*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito";
}
*{
  scrollbar-width: thin;
}
*::-webkit-scrollbar{
  width: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb{
  background-color: #9e9c9c;
  border-radius: 5px;
}

*:focus{
  /* border: 1px solid rgb(41, 207, 129) !important;
  box-shadow: 0 0 0 2px #43dc94 !important; */
  outline-color: rgb(41, 207, 129) ;
  outline: 1;

}

body{
  width: inherit;
  max-width: inherit;
}
html{
  width: 100%;
  max-width: 100%;
}
.mainDiv{
  min-height: 100vh;
  background-color: #f5f5f5;
}
.label-class{
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  margin-bottom: 0.5rem;
  color: rgba(68, 68, 68, 0.5);
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.buttonStandard2{
  font-size: 12px;
  padding: 0.1rem 1rem;
  color: #FF9777;
  background: transparent;
  border: 1px solid #FF9777;
  border-radius: 5px;}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}