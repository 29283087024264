.Mod1-parent-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 25rem;
    min-height: 15rem;
    max-width: 25rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem;
}

.Mod1-parent-container-content-close-icon {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    background: transparent;
    border: none;
}

.Mod1-parent-container-content1 {
    width: 2rem;
    height: 2rem
}

.Mod1-parent-container-content2 {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #444444;
}

.Mod1-parent-container-content3 {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #444444;
    text-align: center;
}

.Mod1-parent-container-content4 {
    width: 100%;
}

.Mod1-parent-container-content5 {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-evenly;
}

@media (max-width: 576px) {
    .Mod1-parent-container-content5 {
        width: 100%;
    }

    .Mod1-parent-container {
        padding: 1rem;
    }

}

.inputField1 {
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.inputField1:focus {
    border-color: rgb(67, 220, 148);
    box-shadow: 0 0 0 1px rgb(67, 220, 148);
}

.inputField1::placeholder {
    color: #444444;
    opacity: 0.5;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon img {
    position: absolute;
    left: 0;
    top: 0px;
    padding: 9px 8px;
    color: #aaa;
}